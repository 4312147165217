import jQuery from 'jquery/dist/jquery';
import * as bootstrap from 'bootstrap'
import Utils from '../../../utils'
import DefaultController from "../../defaultController";
import UserEntity from "../entity";
import Swal from "sweetalert2";

export default class UserList extends DefaultController {
    tableElem: any;
    datatable: any;
    private editModal: any;

    async init() {
        this.entity = "users";
        this.tableElem = jQuery('.datatables-users')
        this.editModal = new bootstrap.Modal((document.querySelector("#editUser") as HTMLElement));
        this.createTable();
        await super.init();
    }

    getEntityData(elem: any) {
        return UserEntity.getEntityData(elem)
    }

    bindListeners() {
        this.tableElem.delegate(".delete-record", "click", async (e: any) => {
            const confirm = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-primary me-3',
                    cancelButton: 'btn btn-label-secondary'
                },
                buttonsStyling: false
            })
            if(confirm.value === true) {
                const id = e.currentTarget.getAttribute("data-id")
                const r = await Utils.entity.destroy(id, this.entity)
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('user.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                } else {
                    this.toastr.error(`${Utils.translate('user.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
                }
                this.datatable.ajax.reload();
            }
        });
        this.tableElem.delegate(".edit-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const requestData = await Utils.entity.get(id, this.entity)
            if (requestData.status === 200) {
                const data = requestData.data[0]
                const form = (document.querySelector("#editUserForm") as HTMLFormElement);
                await Utils.updateElements(data, '', form)
                jQuery("#editUser #user_role").html("")
                data.roles.forEach((role: any) => {
                    const option = new Option(role.name, role.uuid, true, true);
                    jQuery("#editUser #user_role").append(option).trigger('change');

                    // manually trigger the `select2:select` event
                    jQuery("#editUser #user_role").trigger({
                        type: 'select2:select',
                        params: {
                            data: role
                        }
                    });
                })
                this.editModal.show();
            }

        });
        (document.querySelector("#addNewUserForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#addNewUserForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    await this.datatable.ajax.reload();
                    const bsElem = bootstrap.Offcanvas.getInstance((document.querySelector("#offcanvasAddUser") as HTMLElement))
                    if (bsElem) {
                        bsElem.hide();
                    }
                    elem.reset();
                    this.toastr.success(`${Utils.translate('user.name')} ${Utils.translate('generic.messages.created')}`, `${Utils.translate('generic.success')}`)
                }
            }
        });

        (document.querySelector("#editUserForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#editUserForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    await this.datatable.ajax.reload();
                    this.editModal.hide();
                    this.toastr.success(`${Utils.translate('user.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                }
            }
        })
    }

    createTable() {
        this.datatable = this.tableElem.DataTable({
            initComplete: () => {
                this.tableElem.closest(".card").find(".loading-body").addClass("d-none")
                this.tableElem.closest(".card").find(".card-datatable").removeClass("d-none")
                setTimeout(() => {
                        const t = this.datatable;
                        t.columns().every((col: any) => {
                            if (col === 3) {
                                const column = t.column(col);
                                const header = column.header();
                                let filterElem: any = null
                                filterElem = document.createElement('select');
                                filterElem.multiple = true;
                                filterElem.classList.add("form-select")
                                header.appendChild(filterElem);
                                filterElem.addEventListener("click", (e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                })
                                filterElem.style.marginTop = "5px"
                                header.appendChild(filterElem);

                                const options: any = {}
                                options[col] = ["Ja", "Nein"]
                                Object.keys(options).forEach((key: any) => {
                                    const v = options[key].filter((value: any, index: any, array: string | any[]) => array.indexOf(value) === index);
                                    v.forEach((value: any) => {
                                        filterElem.add(new Option(value, value));
                                    })
                                })
                                if (filterElem.nodeName === "SELECT") {
                                    jQuery(filterElem).select2().on('select2:select select2:unselect', (e: any) => {
                                        //@ts-ignore
                                        const values = Array.from(filterElem.querySelectorAll("option:checked")).map((e) => {
                                            return jQuery.fn.DataTable.util.escapeRegex(e.value)
                                        })
                                        column.search(values.join("|").length > 0 ? '^' + values.join("|") + '$' : '', true, false).draw();
                                    });
                                }
                            }
                        });
                }, 10);
            },
            ajax: `/api/v1/users`,
            columns: [
                {data: 'name'},
                {data: 'email'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'}
            ],
            columnDefs: [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (full.msId) {
                            return 'Azure User'
                        } else {
                            return 'Local User'
                        }
                    },
                },
                {
                    targets: 3,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (full.external) {
                            return 'Ja'
                        } else {
                            return 'Nein'
                        }
                    },
                },
                {
                    targets: 4,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return full.roles.map(r => r.name).join(", ");
                    },
                },
                {
                    targets: 5,
                    searchable: false,
                    orderable: false,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (!full.msId) {
                            return `<div class="d-flex align-items-center justify-content-end">
                                  <a href="#" data-id="${data}" class="text-body edit-record">
                                    <i class="ti ti-edit ti-sm me-2"></i>
                                  </a>
                                  <a href="#" data-id="${data}" class="text-body delete-record">
                                    <i class="ti ti-trash ti-sm mx-2"></i>
                                  </a>
                                </div>`
                        } else {
                            return ''
                        }
                    },
                }
            ],
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-2 mt-3"p>' +
                '>',

            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
                {
                    text: `<i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">${Utils.translate('generic.add')}</span>`,
                    className: 'dt-button add-new btn btn-primary m-2',
                    attr: {
                        'data-bs-toggle': 'offcanvas',
                        'data-bs-target': '#offcanvasAddUser'
                    }
                }
            ]
        });
    }
}